import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../Layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"
import { Contact, Mobile } from "elements/Contact"

import { GASTBY_ENCRYPTED_KEY } from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/complete__icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 128) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { module, completed, nextPath, basePath } = props.pageContext
  const complete = data.complete.childImageSharp.fluid

  useEffect(() => {
    if (module.name === "doctor") {
      navigate(basePath)
    }
  }, [module])

  useEffect(() => {
    sessionStorage.removeItem(GASTBY_ENCRYPTED_KEY)
  }, [])

  return (
    <Layout title={module.title || "Thank You!"} seoTitle={`Complete`}>
      <Container isCentered desktop={11} fullhd={10}>
        <div className={classNames("columns is-vcentered")}>
          <div className="column is-5">
            <figure className={classNames("image mx-3")}>
              <Img fluid={complete} alt="Success!" />
            </figure>
          </div>
          <div className="column">
            <div
              dangerouslySetInnerHTML={{
                __html: completed.replace(/\n/g, "<br />"),
              }}
            ></div>
            <Message className="mt-2 is-size-5">
              Samples will be delivered within 5-6 working days of patient
              enrollment verification within NCR, and within 6-10 working days
              of verification outside NCR.
            </Message>
            <p className="mt-2">
              Feel free to reach us at <Mobile /> for questions about the program services.
            </p>
          </div>
        </div>
        <center>
          <Link to={nextPath} className="mt-2 button is-primary is-medium">
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
