import React from "react"
import classNames from "classnames"

const Message = ({ children, className, color }) => {
  return (
    <article className={classNames(`message is-${color} ${className || ""} `)}>
      <div className="message-body">{children}</div>
    </article>
  )
}

export default Message
